import { useState, useEffect } from "react";
import { ShoppingBag } from "lucide-react";
// Removed custom Button and Input imports

export default function ProductPage() {
  const [timeLeft, setTimeLeft] = useState(7260); // 2 hours, 1 minute in seconds

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  const formatTime = (seconds) => {
    const h = Math.floor(seconds / 3600);
    const m = Math.floor((seconds % 3600) / 60);
    const s = seconds % 60;
    return `${h}:${m.toString().padStart(2, "0")}:${s
      .toString()
      .padStart(2, "0")}`;
  };

  return (
    <div className="flex flex-col min-h-screen bg-gradient-to-br from-gray-50 to-gray-100">
      <header className="sticky top-0 z-10 bg-white bg-opacity-90 backdrop-blur-md shadow-md">
        <div className="bg-gradient-to-r from-red-600 to-red-700 text-white text-center py-2 text-sm">
          <span className="animate-marquee inline-block">
            20% Off Bid Packs of 23 or More | 20% Off Bid Packs of 23 or More
          </span>
        </div>
        <div className="container mx-auto flex justify-between items-center px-4 py-4">
          <div className="flex items-center space-x-4">
            <ShoppingBag className="h-8 w-8 text-primary" />
            <h1 className="text-2xl font-bold text-black">GalaxyBidz.com</h1>
          </div>
          <div className="flex items-center space-x-4">
            <button className="border p-2">52 Bids</button>
            <button className="border p-2">Buy Bids</button>
          </div>
        </div>
        <nav className="container mx-auto flex justify-between items-center px-4 py-2 border-t border-gray-200">
          <div className="flex space-x-4">
            <a href="/gallery" className="px-3 py-2 hover:bg-gray-200 rounded">Catalogue</a>
            <button className="px-3 py-2 hover:bg-gray-200 rounded">About Us</button>
            <button className="px-3 py-2 hover:bg-gray-200 rounded">Community</button>
            <button className="px-3 py-2 border rounded">Spin to Win</button>
          </div>
          <div className="flex items-center space-x-4">
            <span className="text-sm font-medium">Auctions (2)</span>
            {/* Placeholder SVG for User Icon */}
            <button className="p-2 hover:bg-gray-200 rounded">
              <svg
                className="h-5 w-5"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M5.121 17.804A13.937 13.937 0 0112 15c2.236 0 4.309.486 6.121 1.804M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                />
              </svg>
            </button>
            {/* Placeholder SVG for Bell Icon */}
            <button className="p-2 hover:bg-gray-200 rounded">
              <svg
                className="h-5 w-5"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
                />
              </svg>
            </button>
          </div>
        </nav>
      </header>

      <main className="flex-grow container mx-auto px-4 py-8">
        <div className="grid md:grid-cols-2 gap-8">
          <div>
            <img
              src="https://store.storeimages.cdn-apple.com/8756/as-images.apple.com/is/iphone-og-image-202309?wid=1200&hei=630&fmt=jpeg&qlt=95&.v=1695326663078"
              alt="iPhone 15"
              className="w-full h-auto rounded-lg shadow-lg"
            />
            <div className="mt-4 grid grid-cols-4 gap-2">
              {[1, 2, 3, 4].map((i) => (
                <img
                  key={i}
                  src="https://store.storeimages.cdn-apple.com/8756/as-images.apple.com/is/iphone-og-image-202309?wid=1200&hei=630&fmt=jpeg&qlt=95&.v=1695326663078"
                  alt={`iPhone 15 thumbnail ${i}`}
                  className="w-full h-auto rounded-md cursor-pointer hover:opacity-75 transition-opacity"
                />
              ))}
            </div>
          </div>
          <div>
            <h1 className="text-3xl font-bold mb-2">iPhone 15 Pro 256GB</h1>
            <div className="flex items-center mb-4">
              <div className="flex">
                {[1, 2, 3, 4, 5].map((star) => (
                  <svg
                    key={star}
                    className="h-5 w-5 text-yellow-400"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path d="M9.049 2.927C9.343 2.213 10.657 2.213 10.951 2.927L12.406 6.865A1 1 0 0013.91 7.552L17.461 8.297C18.34 8.498 18.737 9.604 17.94 10.15L14.788 13.012A1 1 0 0014.313 14.2L15.02 17.75C15.249 18.63 14.403 19.294 13.56 18.89L10 16.79 6.44 18.89C5.597 19.294 4.751 18.63 4.98 17.75L5.687 14.2A1 1 0 005.212 13.012L2.06 10.15C1.263 9.604 1.66 8.498 2.539 8.297L6.09 7.552A1 1 0 007.594 6.865L9.049 2.927z" />
                  </svg>
                ))}
              </div>
              <span className="ml-2 text-sm text-gray-600">(245 reviews)</span>
            </div>
            <p className="text-gray-600 mb-4">
              Experience the cutting-edge technology of the iPhone 15 Pro. With its powerful A17 Pro chip, advanced camera
              system, and stunning Super Retina XDR display, it's the ultimate smartphone for tech enthusiasts and
              professionals alike.
            </p>
            <div className="mb-6">
              <h2 className="text-xl font-semibold mb-2">Current Bid</h2>
              <div className="text-3xl font-bold text-primary">$899</div>
              <div className="text-sm text-gray-500">RRP: $1,499</div>
            </div>
            <div className="mb-6">
              <h2 className="text-xl font-semibold mb-2">Time Remaining</h2>
              <div className="text-2xl font-bold">{formatTime(timeLeft)}</div>
            </div>
            <div className="flex space-x-4 mb-6">
              <button className="flex-1 bg-blue-500 text-white p-2 rounded hover:bg-blue-600 transition">
                Place Bid
              </button>
              <button className="flex-1 border p-2 rounded hover:bg-gray-200 transition">
                Buy Now
              </button>
              <button className="p-2 rounded hover:bg-gray-200 transition">
                {/* Placeholder SVG for Heart Icon */}
                <svg
                  className="h-5 w-5 text-red-500"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            </div>
            <div className="bg-gray-100 p-4 rounded-lg">
              <h2 className="text-lg font-semibold mb-2">Product Details</h2>
              <ul className="list-disc list-inside text-sm text-gray-600">
                <li>6.1-inch Super Retina XDR display</li>
                <li>A17 Pro chip for unprecedented performance</li>
                <li>Pro camera system with 48MP main camera</li>
                <li>Up to 29 hours of video playback</li>
                <li>Ceramic Shield front</li>
                <li>Face ID for secure authentication</li>
              </ul>
            </div>
          </div>
        </div>
      </main>

      <footer className="bg-gradient-to-b from-gray-800 to-gray-900 text-white py-12">
        <div className="container mx-auto px-4">
          <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
            <div>
              <h4 className="font-semibold mb-4">Customer Service</h4>
              <ul className="space-y-2">
                <li>
                  <button className="text-gray-300 hover:text-white p-0">
                    Contact Us
                  </button>
                </li>
                <li>
                  <button className="text-gray-300 hover:text-white p-0">
                    Returns Policy
                  </button>
                </li>
                <li>
                  <button className="text-gray-300 hover:text-white p-0">
                    Privacy Policy
                  </button>
                </li>
              </ul>
            </div>
            <div>
              <h4 className="font-semibold mb-4">Legal</h4>
              <ul className="space-y-2">
                <li>
                  <button className="text-gray-300 hover:text-white p-0">
                    Terms of Use
                  </button>
                </li>
                <li>
                  <button className="text-gray-300 hover:text-white p-0">
                    Australian Consumer Law
                  </button>
                </li>
                <li>
                  <button className="text-gray-300 hover:text-white p-0">
                    Report Suspicious Behavior
                  </button>
                </li>
              </ul>
            </div>
            <div>
              <h4 className="font-semibold mb-4">About Us</h4>
              <ul className="space-y-2">
                <li>
                  <button className="text-gray-300 hover:text-white p-0">
                    Our Story
                  </button>
                </li>
                <li>
                  <button className="text-gray-300 hover:text-white p-0">
                    Careers
                  </button>
                </li>
                <li>
                  <button className="text-gray-300 hover:text-white p-0">
                    Press
                  </button>
                </li>
              </ul>
            </div>
            <div>
              <h4 className="font-semibold mb-4">Follow Us</h4>
              <div className="flex space-x-4">
                <button className="text-gray-300 hover:text-white">
                  {/* Placeholder SVG for Facebook Icon */}
                  <svg
                    className="h-5 w-5"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                  >
                    <path d="M22 12a10 10 0 10-11.5 9.9v-7h-2v-3h2v-2c0-2.2 1.3-3.4 3.3-3.4.96 0 1.96.17 1.96.17v2.16h-1.11c-1.1 0-1.44.68-1.44 1.38v1.65h2.45l-.39 3h-2.06v7A10 10 0 0022 12z" />
                  </svg>
                </button>
                <button className="text-gray-300 hover:text-white">
                  {/* Placeholder SVG for Twitter Icon */}
                  <svg
                    className="h-5 w-5"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                  >
                    <path d="M23 3a10.9 10.9 0 01-3.14 1.53A4.48 4.48 0 0022.43 1a9.1 9.1 0 01-2.88 1.1A4.52 4.52 0 0016.67 0c-2.5 0-4.5 2-4.5 4.5 0 .35.04.7.12 1.04C7.69 4.77 4.07 3.1 1.64.58a4.5 4.5 0 00-.61 2.27c0 1.56.8 2.94 2.02 3.76a4.5 4.5 0 01-2.04-.56v.06c0 2.2 1.56 4.03 3.63 4.45a4.5 4.5 0 01-2.03.08c.57 1.8 2.22 3.1 4.16 3.13A9.1 9.1 0 010 19.54a12.9 12.9 0 006.98 2.04c8.38 0 12.95-6.93 12.95-12.95 0-.2-.01-.4-.02-.6A9.3 9.3 0 0023 3z" />
                  </svg>
                </button>
              </div>
            </div>
          </div>
          <div className="mt-8 pt-8 border-t border-gray-700 text-center">
            {/* Placeholder SVG for ShoppingBag */}
            <svg
              className="h-8 w-8 mx-auto mb-4 text-primary"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M3 3h18M3 21h18"
              />
            </svg>
            <p className="text-sm text-gray-400">&copy; 2024 GalaxyBidz. All rights reserved.</p>
          </div>
        </div>
      </footer>
    </div>
  );
}

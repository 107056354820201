import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';

import Component from './components/Component';
import BuyNow from './components/BuyNow';
import Gallery from './components/Gallery';

function App() {
  return (
    <div className="App">
      {/* Router wraps the entire app to enable routing */}
      <Router>
        <Routes>
          {/* Define each route path and associate it with a component */}
          <Route path="/" element={<Component />} />
          <Route path="/buy-now" element={<BuyNow />} />
          <Route path="/gallery" element={<Gallery />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;

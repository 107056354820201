import { useState, useEffect } from "react";
import { ShoppingBag } from "lucide-react";
// Mock product data
const products = [
  { id: 1, name: "iPhone 15 Pro", category: "Electronics", currentBid: 899, buyNow: 1499, timeLeft: 7200, image: "/placeholder.svg?height=300&width=300" },
  { id: 2, name: "MacBook Air M2", category: "Electronics", currentBid: 1099, buyNow: 1799, timeLeft: 3600, image: "/placeholder.svg?height=300&width=300" },
  { id: 3, name: "Sony A7 III Camera", category: "Electronics", currentBid: 1299, buyNow: 2199, timeLeft: 5400, image: "/placeholder.svg?height=300&width=300" },
  { id: 4, name: "Nike Air Jordan 1", category: "Fashion", currentBid: 199, buyNow: 349, timeLeft: 1800, image: "/placeholder.svg?height=300&width=300" },
  { id: 5, name: "Rolex Submariner", category: "Jewelry", currentBid: 8999, buyNow: 14999, timeLeft: 10800, image: "/placeholder.svg?height=300&width=300" },
  { id: 6, name: "Louis Vuitton Handbag", category: "Fashion", currentBid: 1499, buyNow: 2499, timeLeft: 3600, image: "/placeholder.svg?height=300&width=300" },
  { id: 7, name: "PlayStation 5", category: "Electronics", currentBid: 399, buyNow: 599, timeLeft: 7200, image: "/placeholder.svg?height=300&width=300" },
  { id: 8, name: "Dyson V11 Vacuum", category: "Home", currentBid: 349, buyNow: 599, timeLeft: 5400, image: "/placeholder.svg?height=300&width=300" },
];

export default function GalleryPage() {
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredProducts, setFilteredProducts] = useState(products);
  const [sortBy, setSortBy] = useState("default");

  useEffect(() => {
    const results = products.filter((product) =>
      product.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      product.category.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredProducts(results);
  }, [searchTerm]);

  useEffect(() => {
    let sorted = [...filteredProducts];
    switch (sortBy) {
      case "priceLowHigh":
        sorted.sort((a, b) => a.currentBid - b.currentBid);
        break;
      case "priceHighLow":
        sorted.sort((a, b) => b.currentBid - a.currentBid);
        break;
      case "timeEndingSoon":
        sorted.sort((a, b) => a.timeLeft - b.timeLeft);
        break;
      default:
        break;
    }
    setFilteredProducts(sorted);
  }, [sortBy]);

  const formatTime = (seconds) => {
    const h = Math.floor(seconds / 3600);
    const m = Math.floor((seconds % 3600) / 60);
    const s = seconds % 60;
    return `${h}:${m.toString().padStart(2, "0")}:${s.toString().padStart(2, "0")}`;
  };

  return (
    <div className="flex flex-col min-h-screen bg-gradient-to-br from-gray-50 to-gray-100">
      <header className="sticky top-0 z-10 bg-white bg-opacity-90 backdrop-blur-md shadow-md">
        <div className="bg-gradient-to-r from-red-600 to-red-700 text-white text-center py-2 text-sm">
          <marquee>20% Off Bid Packs of 23 or More | 20% Off Bid Packs of 23 or More | 20% Off Bid Packs of 23 or More</marquee>
        </div>
        <div className="container mx-auto flex justify-between items-center px-4 py-4">
          <div className="flex items-center space-x-4">
            <ShoppingBag className="h-8 w-8 text-primary" />
            <h1 className="text-2xl font-bold text-black">
              GalaxyBidz.com
            </h1>
          </div>
          <div className="flex items-center space-x-4">
            <button className="border p-2">52 Bids</button>
            <button className="border p-2">Buy Bids</button>
          </div>
        </div>
        <nav className="container mx-auto flex justify-between items-center px-4 py-2 border-t border-gray-200">
          <div className="flex space-x-4">
            <button className="p-2">Catalogue</button>
            <button className="p-2">About Us</button>
            <button className="p-2">Community</button>
            <button className="border p-2">Spin to Win</button>
          </div>
        </nav>
      </header>

      <main className="flex-grow container mx-auto px-4 py-8">
        <div className="mb-8">
          <h1 className="text-3xl font-bold mb-4">Product Gallery</h1>
          <div className="flex flex-col md:flex-row justify-between items-start md:items-center space-y-4 md:space-y-0 md:space-x-4">
            <div className="relative w-full md:w-1/2">
              <input
                type="text"
                className="pl-10 pr-4 w-full border"
                placeholder="Search products..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            <select
              value={sortBy}
              onChange={(e) => setSortBy(e.target.value)}
              className="border p-2 w-full md:w-[200px]"
            >
              <option value="default">Default</option>
              <option value="priceLowHigh">Price: Low to High</option>
              <option value="priceHighLow">Price: High to Low</option>
              <option value="timeEndingSoon">Time: Ending Soon</option>
            </select>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
          {filteredProducts.map((product) => (
            <div key={product.id} className="bg-white rounded-xl shadow-lg overflow-hidden transition-transform hover:scale-105">
              <img
                src={product.image}
                alt={product.name}
                className="w-full h-48 object-cover"
              />
              <div className="p-4">
                <h3 className="font-bold text-lg mb-2">{product.name}</h3>
                <p className="text-sm text-gray-500 mb-2">{product.category}</p>
                <div className="flex justify-between items-center mb-2">
                  <span className="text-sm font-medium">Current Bid:</span>
                  <span className="text-lg font-semibold text-primary">${product.currentBid}</span>
                </div>
                <div className="flex justify-between items-center mb-2">
                  <span className="text-sm font-medium">Buy Now:</span>
                  <span className="text-sm text-gray-500">${product.buyNow}</span>
                </div>
                <div className="flex justify-between items-center mb-4">
                  <span className="text-sm font-medium">Time Left:</span>
                  <span className="text-sm font-bold">{formatTime(product.timeLeft)}</span>
                </div>
                <div className="flex space-x-2">
                  <a href="/buy-now" className="flex-1 bg-blue-500 text-white p-2 text-center">Bid Now</a>
                  <a href="/buy-now" className="flex-1 border p-2 text-center">Buy Now</a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </main>

      <footer className="bg-gradient-to-b from-gray-800 to-gray-900 text-white py-12">
        <div className="container mx-auto px-4">
          <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
            <div>
              <h4 className="font-semibold mb-4">Customer Service</h4>
              <ul className="space-y-2">
                <li>
                  <button className="text-gray-300 hover:text-white p-0">Contact Us</button>
                </li>
                <li>
                  <button className="text-gray-300 hover:text-white p-0">Returns Policy</button>
                </li>
                <li>
                  <button className="text-gray-300 hover:text-white p-0">Privacy Policy</button>
                </li>
              </ul>
            </div>
            <div>
              <h4 className="font-semibold mb-4">Legal</h4>
              <ul className="space-y-2">
                <li>
                  <button className="text-gray-300 hover:text-white p-0">Terms of Use</button>
                </li>
                <li>
                  <button className="text-gray-300 hover:text-white p-0">Australian Consumer Law</button>
                </li>
                <li>
                  <button className="text-gray-300 hover:text-white p-0">Report Suspicious Behavior</button>
                </li>
              </ul>
            </div>
            <div>
              <h4 className="font-semibold mb-4">About Us</h4>
              <ul className="space-y-2">
                <li>
                  <button className="text-gray-300 hover:text-white p-0">Our Story</button>
                </li>
                <li>
                  <button className="text-gray-300 hover:text-white p-0">Careers</button>
                </li>
                <li>
                  <button className="text-gray-300 hover:text-white p-0">Press</button>
                </li>
              </ul>
            </div>
            <div>
              <h4 className="font-semibold mb-4">Follow Us</h4>
              <div className="flex space-x-4">
                <button className="text-gray-300 hover:text-white">Facebook</button>
                <button className="text-gray-300 hover:text-white">Twitter</button>
              </div>
            </div>
          </div>
          <div className="mt-8 pt-8 border-t border-gray-700 text-center">
            <p className="text-sm text-gray-400">&copy; 2024 GalaxyBidz. All rights reserved.</p>
          </div>
        </div>
      </footer>
    </div>
  );
}

import { useState, useEffect } from "react";
import { Bell, Search, ShoppingBag, User, Star } from "lucide-react";

export default function Component() {
  const [timeLeft, setTimeLeft] = useState(7260); // Example: 2 hours, 1 minute in seconds

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const formatTime = (seconds) => {
    const h = Math.floor(seconds / 3600);
    const m = Math.floor((seconds % 3600) / 60);
    const s = seconds % 60;
    return `${h}:${m.toString().padStart(2, '0')}:${s.toString().padStart(2, '0')}`;
  };

  return (
    <div className="flex flex-col min-h-screen bg-gradient-to-br from-gray-50 to-gray-100">
      <header className="sticky top-0 z-10 bg-white bg-opacity-90 backdrop-blur-md shadow-md">
        <div className="bg-gradient-to-r from-red-600 to-red-700 text-white text-center py-2 text-sm">
          <marquee>20% Off Bid Packs of 23 or More | 20% Off Bid Packs of 23 or More | 20% Off Bid Packs of 23 or More</marquee>
        </div>
        <div className="container mx-auto flex justify-between items-center px-4 py-4">
          <div className="flex items-center space-x-4">
            <ShoppingBag className="h-8 w-8 text-primary" />
            <h1 className="text-2xl font-bold text-black">
              GalaxyBidz.com
            </h1>
          </div>
          <div className="flex items-center space-x-4">
            <div className="relative">
              <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
              <input
                type="text"
                className="pl-10 pr-4 w-64 bg-gray-100"
                placeholder="Rolex, iPhone, Handbag ..."
              />
            </div>
            <button className="border p-2 bg-white bg-opacity-50 backdrop-blur-sm">
              52 Bids
            </button>
            <button className="p-2 border border-black text-black bg-white hover:bg-gray-100 transition-colors">
              Buy Bids
            </button>
          </div>
        </div>
        <nav className="container mx-auto flex justify-between items-center px-4 py-2 border-t border-gray-200">
          <div className="flex space-x-4">
            <a href="/gallery" className="p-2">Catalogue</a>
            <button className="p-2">About Us</button>
            <button className="p-2">Community</button>
            <button className="border p-2 bg-white bg-opacity-50 backdrop-blur-sm">
              Spin to Win
            </button>
          </div>
          <div className="flex items-center space-x-4">
            <span className="text-sm font-medium">Auctions (2)</span>
            <button className="p-2">
              <User className="h-5 w-5" />
            </button>
            <button className="p-2">
              <Bell className="h-5 w-5" />
            </button>
          </div>
        </nav>
      </header>

      <main className="flex-grow">
        <section className="bg-gradient-to-r from-blue-400 to-purple-500 text-white py-16">
          <div className="container mx-auto px-4 flex justify-between items-center">
            <div>
              <h2 className="text-5xl font-bold mb-2">iPhone 15</h2>
              <h3 className="text-3xl mb-4 opacity-90">Newphoria</h3>
              <p className="mb-6 text-lg opacity-80">Available Now</p>
              <a href="/buy-now" className="p-2 bg-white text-black hover:bg-gray-100 transition-colors">
                Buy Now
              </a>
            </div>
            <img
              src="https://www.mobileciti.com.au/media/catalog/category/iPhone_15_1_2.jpg"
              alt="iPhone 15"
              className="h-80 w-auto rounded-lg shadow-2xl"
            />
          </div>
        </section>

        <section className="py-16 bg-gradient-to-b from-gray-50 via-white to-gray-50">
          <div className="container mx-auto px-4">
            <h2 className="text-3xl font-bold mb-8 text-center">Recommended for You</h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              {[1, 2, 3].map((item) => (
                <div
                  key={item}
                  className="bg-white rounded-xl shadow-lg overflow-hidden transition-transform hover:scale-105"
                >
                  <img
                    src="https://store.storeimages.cdn-apple.com/8756/as-images.apple.com/is/iphone-og-image-202309?wid=1200&hei=630&fmt=jpeg&qlt=95&.v=1695326663078"
                    alt="iPhone"
                    className="w-full h-48 object-cover"
                  />
                  <div className="p-6">
                    <h3 className="font-bold text-xl mb-2">iPhone 15 256GB</h3>
                    <div className="flex justify-between items-center mb-2">
                      <span className="text-sm text-gray-500">RRP: $1500</span>
                      <span className="text-lg font-semibold text-primary">$250</span>
                    </div>
                    <div className="flex justify-between items-center mb-4">
                      <span className="text-sm font-medium">Time Left:</span>
                      <span className="text-sm font-bold">{formatTime(timeLeft)}</span>
                    </div>
                    <a href="/buy-now" className="w-full p-2 border border-black text-black bg-white hover:bg-gray-100 transition-colors shadow-md hover:shadow-lg">
                      Buy Now
                    </a>
                  </div>
                </div>
              ))}
            </div>
            <div className="text-center mt-8">
              <button className="underline text-primary">View More</button>
            </div>
          </div>
        </section>

        <section className="py-16 bg-gradient-to-t from-gray-50 via-white to-gray-50">
          <div className="container mx-auto px-4">
            <h2 className="text-3xl font-bold mb-8 text-center">Popular Products</h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              {[1, 2, 3].map((item) => (
                <div
                  key={item}
                  className="bg-white rounded-xl shadow-lg overflow-hidden transition-transform hover:scale-105"
                >
                  <img
                    src="https://store.storeimages.cdn-apple.com/8756/as-images.apple.com/is/iphone-og-image-202309?wid=1200&hei=630&fmt=jpeg&qlt=95&.v=1695326663078"
                    alt="iPhone"
                    className="w-full h-48 object-cover"
                  />
                  <div className="p-6">
                    <div className="flex justify-between items-center mb-2">
                      <h3 className="font-bold text-xl">iPhone 15 256GB</h3>
                      <Star className="h-5 w-5 text-yellow-400 fill-current" />
                    </div>
                    <div className="flex justify-between items-center mb-2">
                      <span className="text-sm text-gray-500">RRP: $1500</span>
                      <span className="text-lg font-semibold text-primary">$250</span>
                    </div>
                    <div className="flex justify-between items-center mb-4">
                      <span className="text-sm font-medium">Time Remaining:</span>
                      <span className="text-sm font-bold">{formatTime(timeLeft)}</span>
                    </div>
                    <a href="/buy-now" className="w-full p-2 border border-black text-black bg-white hover:bg-gray-100 transition-colors shadow-md hover:shadow-lg">
                      Buy Now
                    </a>
                  </div>
                </div>
              ))}
            </div>
            <div className="text-center mt-8">
              <button className="underline text-primary">View More</button>
            </div>
          </div>
        </section>
      </main>

      <footer className="bg-gradient-to-b from-gray-800 to-gray-900 text-white py-12">
        <div className="container mx-auto px-4">
          <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
            <div>
              <h4 className="font-semibold mb-4">Customer Service</h4>
              <ul className="space-y-2">
                <li>
                  <button className="text-gray-300 hover:text-white p-0">
                    Contact Us
                  </button>
                </li>
                <li>
                  <button className="text-gray-300 hover:text-white p-0">
                    Returns Policy
                  </button>
                </li>
                <li>
                  <button className="text-gray-300 hover:text-white p-0">
                    Privacy Policy
                  </button>
                </li>
              </ul>
            </div>
            <div>
              <h4 className="font-semibold mb-4">Legal</h4>
              <ul className="space-y-2">
                <li>
                  <button className="text-gray-300 hover:text-white p-0">
                    Terms of Use
                  </button>
                </li>
                <li>
                  <button className="text-gray-300 hover:text-white p-0">
                    Australian Consumer Law
                  </button>
                </li>
                <li>
                  <button className="text-gray-300 hover:text-white p-0">
                    Report Suspicious Behavior
                  </button>
                </li>
              </ul>
            </div>
            <div>
              <h4 className="font-semibold mb-4">About Us</h4>
              <ul className="space-y-2">
                <li>
                  <button className="text-gray-300 hover:text-white p-0">
                    Our Story
                  </button>
                </li>
                <li>
                  <button className="text-gray-300 hover:text-white p-0">
                    Careers
                  </button>
                </li>
                <li>
                  <button className="text-gray-300 hover:text-white p-0">
                    Press
                  </button>
                </li>
              </ul>
            </div>
            <div>
              <h4 className="font-semibold mb-4">Follow Us</h4>
              <div className="flex space-x-4">
                <button className="text-gray-300 hover:text-white p-2">
                  <svg
                    className="h-5 w-5"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                  >
                    {/* SVG path for a social icon */}
                  </svg>
                </button>
                <button className="text-gray-300 hover:text-white p-2">
                  <svg
                    className="h-5 w-5"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                  >
                    {/* SVG path for a social icon */}
                  </svg>
                </button>
              </div>
            </div>
          </div>
          <div className="mt-8 pt-8 border-t border-gray-700 text-center">
            <ShoppingBag className="h-8 w-8 mx-auto mb-4 text-primary" />
            <p className="text-sm text-gray-400">&copy; 2024 GalaxyBidz. All rights reserved.</p>
          </div>
        </div>
      </footer>
    </div>
  );
}
